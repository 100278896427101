
import { computed, reactive } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { AXIOS_UTILS } from '@/utils/AxiosUtils'
import { ACTIONS } from '@/store/StoreActions'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as bootstrap from 'bootstrap'

export default {
    name: 'Login',
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    setup() {
        const store = useStore()
        const router = useRouter()

        function validate(): boolean {
            return true
        }
        function login() {
            if (validate()) {
                AXIOS_UTILS.ACCOUNT.LOGIN(state.username, state.password)
                    .then((response) => {
                        if (
                            response.data.status.code === 0 ||
                            response.data.status.code === 53
                        ) {
                            store
                                .dispatch(ACTIONS.LOGIN, response.data)
                                .then(() => {
                                    AXIOS_UTILS.ACCOUNT.GET_DATA(_token.value)
                                        .then((response) => {
                                            store.dispatch(
                                                ACTIONS.SET_DATA,
                                                response.data
                                            )
                                            router.push('/')
                                        })
                                        .catch((error) => {
                                            console.error(error)
                                        })
                                })
                        } else {
                            state.errorMessage = response.data.status.desc

                            // eslint-disable-next-line no-undef
                            $('#modal').modal('show')
                        }
                    })
                    .catch((error) => {
                        console.error(error)
                    })
            }
        }
        const _token = computed(() => {
            return store.state.user.token
        })
        const state = reactive({
            loading: false,
            username: '',
            password: '',
            errorMessage: '',
        })

        return {
            state,
            login,
        }
    },
}
