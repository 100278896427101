
import { computed, onBeforeMount, reactive } from 'vue'
import { useStore } from 'vuex'

import CardList from '@/components/CardList.vue'
import { ACTIONS } from '@/store/StoreActions'
import { AXIOS_UTILS, redirectToLogin } from '@/utils/AxiosUtils'

export default {
    name: 'Collections',
    components: { CardList },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    setup() {
        const store = useStore()

        onBeforeMount(() => {
            fetchCollections()
        })

        function fetchCollections() {
            state.loading = true
            store.dispatch(ACTIONS.COLLECTION_CLEAR_STATE).then(() => {
                AXIOS_UTILS.COLLECTIONS.GET.LIST(_token.value)
                    .then((response) => {
                        if (response.data.status.code == 0) {
                            store.dispatch(
                                ACTIONS.FILL_COLLECTION_LIST,
                                response.data
                            )
                            state.loading = false
                        } else if (response.data.status.code == 55)
                            redirectToLogin()
                    })
                    .catch((error) => {
                        console.error(error)
                        state.loading = false
                    })
            })
        }

        function reload() {
            state.loading = true
            fetchCollections()
        }

        function addColection(): void {
            state.form.formLoading = true
            AXIOS_UTILS.COLLECTIONS.POST.NEW_COLLECTION(
                _token.value,
                state.form.name,
                state.form.url,
                _reqSkipHeaders.value ?? [''],
                _reqHideReqBody.value ?? ['']
            )
                .then((response) => {
                    state.form.formLoading = false
                    if (response.data.status.code != 55) {
                        // eslint-disable-next-line no-undef
                        $('#Modal .close').trigger('click')
                        state.form = {
                            formLoading: false,
                            name: '',
                            url: '',
                            skipHeaders: '',
                            hideRequestBody: '',
                        }
                    } else redirectToLogin()
                })
                .catch((error) => {
                    console.error(error)
                    state.form.formLoading = false
                })
        }

        const _token = computed(() => {
            return store.state.user.token
        })

        const collection = computed(() => {
            return store.state.collection.list
        })

        const _reqSkipHeaders = computed(() => {
            let _array = state.form.skipHeaders.split(',')
            return _array
        })

        const _reqHideReqBody = computed(() => {
            let _array = state.form.hideRequestBody.split(',')
            return _array
        })

        const state = reactive({
            actionLabel: 'Open collection',
            loading: true,
            isCollection: true,
            form: {
                formLoading: false,
                name: '',
                url: '',
                skipHeaders: '',
                hideRequestBody: '',
            },
        })

        return { state, addColection, collection, reload }
    },
}
