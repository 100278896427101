/* eslint-disable */

import { Commit } from 'vuex'

export const EnvironmentStore = {
    namespace: true,
    state: {
        storeName: 'Environment',
        total: null,
        list: [],
    },
    mutations: {
        ADD_ENVIRONMENT(state: { list: any[] }, payload: any): void {
            state.list.push(payload)
        },
        SET_TOTAL(
            state: {
                total: number
            },
            payload: number
        ): void {
            state.total = payload
        },
        CLEAR_ENV(state: any, payload: any) {
            state.list = payload.list
            state.total = payload.total
        },
    },
    actions: {
        fillEnvironmentList(
            { commit }: { commit: Commit },
            // eslint-disable-next-line
            environmentJson: any
        ): void {
            const _environments = environmentJson.data.environments

            const totalEnvs: number = _environments.total

            if (totalEnvs > 0) {
                for (let i = 0; i < _environments.list.length; i++) {
                    const env = _environments.list[i]
                    commit('ADD_ENVIRONMENT', env)
                }
            } else return
        },
        addEnvironment(
            { commit }: { commit: Commit },
            // eslint-disable-next-line
            environmentJson: any
        ): void {
            const env = environmentJson.data
            commit('ADD_ENVIRONMENT', env)
        },
        environmentClearState({ commit }: { commit: Commit }): void {
            commit('CLEAR_ENV', {
                total: null,
                list: [],
            })
        },
    },
    getters: {},
}
