
import { URL } from '@/utils/AxiosUtils'
import { defineComponent, PropType, reactive } from 'vue'

export default defineComponent({
    name: 'HistoryCardList',
    props: {
        actionLabel: {
            type: String,
            required: false,
        },
        list: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            type: Array as PropType<Array<any>>,
            required: true,
        },
    },
    setup() {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        function event(item: any): void {
            window.open(
                URL + '/testing/history/show?shareid=' + item.shareId,
                '_blank'
            )
        }

        const state = reactive({})

        return {
            state,
            event,
        }
    },
})
