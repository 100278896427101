
import { defineComponent, reactive } from 'vue'
import NavBar from './components/NavBar.vue'

export default defineComponent({
    name: 'App',
    components: {
        NavBar,
    },
    setup() {
        const state = reactive({})
        return {
            state,
        }
    },
})
