
import { computed, onBeforeMount, reactive } from 'vue'
import { useStore } from 'vuex'

import CardList from '@/components/CardList.vue'
import { AXIOS_UTILS, redirectToLogin } from '@/utils/AxiosUtils'
import { ACTIONS } from '@/store/StoreActions'
import { useRouter } from 'vue-router'

export default {
    name: 'Environments',
    components: { CardList },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    setup() {
        const store = useStore()
        const router = useRouter()

        onBeforeMount(() => {
            fetchEnvironments()
        })

        function fetchEnvironments() {
            state.loading = true
            store.dispatch(ACTIONS.ENVIRONMENT_CLEAR_STATE, '').then(() => {
                AXIOS_UTILS.ENVIRONMENTS.GET.LIST(_token.value)
                    .then((response) => {
                        if (response.data.status.code == 0) {
                            store.dispatch(
                                ACTIONS.FILL_ENVIRONMENT_LIST,
                                response.data
                            )
                            state.loading = false
                        } else if (response.data.status.code == 55)
                            redirectToLogin()
                    })
                    .catch((error) => {
                        console.error(error)
                        state.loading = false
                    })
            })
        }
        function reload() {
            state.loading = true
            fetchEnvironments()
        }

        function addEnv(): void {
            state.form.formLoading = true
            AXIOS_UTILS.ENVIRONMENTS.POST.NEW_ENVIRONMENT(
                _token.value,
                JSON.parse(state.form.newEnvJson)
            )
                .then((response) => {
                    state.form.formLoading = false

                    if (response.data.status.code != 55) {
                        // eslint-disable-next-line no-undef
                        $('#Modal .close').trigger('click')
                        router.push('/')
                    } else redirectToLogin()
                })
                .catch((error) => {
                    console.error(error)
                    state.form.formLoading = false
                })
        }

        const readFile = (event: { target: { files: never } }): void => {
            const _reader = new FileReader()

            _reader.onload = function (e): void {
                state.form.newEnvJson = e.target?.result?.toString() || ''
            }
            _reader.readAsText(event.target.files[0])
        }

        const _token = computed(() => {
            return store.state.user.token
        })

        const environments = computed(() => {
            return store.state.environment.list
        })

        const state = reactive({
            actionLabel: 'Edit env',
            loading: true,
            isCollection: false,
            form: {
                formLoading: false,
                newEnvJson: '',
            },
        })

        return { state, addEnv, environments, readFile, reload }
    },
}
