import { createStore } from 'vuex'

import { CollectionStore } from './modules/CollectionStore'
import { EnvironmentStore } from './modules/EnvironmentStore'
import { UserStore } from './modules/UserStore'
import { TestStore } from './modules/TestStore'

export default createStore({
    modules: {
        collection: CollectionStore,
        environment: EnvironmentStore,
        test: TestStore,
        user: UserStore,
    },
})
