
import { computed, onBeforeMount, reactive } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { AXIOS_UTILS, redirectToLogin } from '@/utils/AxiosUtils'

export default {
    name: 'EditCollection',
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    setup() {
        const store = useStore()
        const route = useRoute()
        const router = useRouter()

        onBeforeMount(() => {
            AXIOS_UTILS.COLLECTIONS.GET.BY_ID(
                _token.value,
                _colId.value.toString()
            )
                .then((response) => {
                    state.collection = response.data.data
                    _setFormLayoutData()
                    state.loading = false
                })
                .catch((error) => {
                    state.loading = false
                    console.error(error)
                })
        })

        function _setFormLayoutData() {
            state.form.url = state.collection.url
            state.form.name = state.collection.name
            state.form.skipHeaders = state.collection.skipHeaders.replace(
                /[[\]"]/g,
                ''
            )
            state.form.hideRequestBody = state.collection.hideRequestBody.replace(
                /[[\]"]/g,
                ''
            )
        }

        function updateCollection() {
            AXIOS_UTILS.COLLECTIONS.PUT.UPDATE_COLLECTION(
                _token.value,
                _colId.value.toString(),
                {
                    name: state.form.name,
                    url: state.form.url,
                    skipHeaders: _reqSkipHeaders.value,
                    hideRequestBody: _reqHideReqBody.value,
                }
            )
                .then((response) => {
                    if (response.data.status.code == 0) {
                        state.responseSuccess = true
                        state.responseTitle = 'Collection updated succesfully'
                        state.responseMessage = 'Click next to continue'
                        // eslint-disable-next-line no-undef
                        $('#modal').modal('show')
                    } else if (response.data.status.code == 55)
                        redirectToLogin()
                })
                .catch((error) => {
                    console.error(error)
                })
        }

        function next() {
            // eslint-disable-next-line no-undef
            $('#modal').modal('hide')
            router.push('/collections')
        }

        const _token = computed(() => {
            return store.state.user.token
        })

        const _colId = computed(() => {
            return route.params.id
        })

        const _reqSkipHeaders = computed(() => {
            let _array = state.form.skipHeaders.split(',')
            return _array
        })

        const _reqHideReqBody = computed(() => {
            let _array = state.form.hideRequestBody.split(',')
            return _array
        })

        const state = reactive({
            name: 'Edit collections',
            loading: true,
            responseMessage: '',
            responseTitle: '',
            responseSuccess: false,
            collection: {
                id: 0,
                name: 'name',
                url: '',
                skipHeaders: '',
                hideRequestBody: '',
            },
            form: {
                url: '',
                name: '',
                skipHeaders: '',
                hideRequestBody: '',
            },
        })

        return { state, updateCollection, next }
    },
}
