
import { computed, onBeforeMount, reactive } from 'vue'
import { useStore } from 'vuex'
import { AXIOS_UTILS, redirectToLogin } from '@/utils/AxiosUtils'
import { useRoute, useRouter } from 'vue-router'

export default {
    name: 'EditEnvironment',
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    setup() {
        const store = useStore()
        const route = useRoute()
        const router = useRouter()

        onBeforeMount(() => {
            AXIOS_UTILS.ENVIRONMENTS.GET.BY_ID(
                _token.value,
                _envId.value.toString()
            )
                .then((response) => {
                    if (response.data.status.code == 0) {
                        const _envJson = response.data.data.json
                        if (_envJson != null) {
                            state.jsonContent = true
                            state.environment.id = response.data.data.id
                            state.environment.name = response.data.data.name
                            state.environment.json = JSON.stringify(_envJson)

                            state.form.name = state.environment.name
                            state.form.json = JSON.stringify(_envJson)

                            state.loading = false
                        } else {
                            state.loading = false
                        }
                    } else if (response.data.status.code == 55)
                        redirectToLogin()
                })
                .catch((error) => {
                    state.loading = false
                    console.error(error)
                })
        })

        function applyChanges() {
            AXIOS_UTILS.ENVIRONMENTS.PUT.UPDATE_ENVIRONMENT(
                _token.value,
                _envId.value.toString(),
                {
                    id: state.environment.id,
                    name: state.form.name,
                    json: JSON.parse(state.form.json),
                }
            )
                .then((response) => {
                    if (response.data.status.code == 0) {
                        state.responseSuccess = true
                        state.responseTitle = 'Environment updated succesfully'
                        state.responseMessage = 'Click next to continue'
                        // eslint-disable-next-line no-undef
                        $('#modal').modal('show')
                    } else if (response.data.status.code == 55)
                        redirectToLogin()
                })
                .catch((error) => {
                    console.error(error)
                })
        }
        function next() {
            // eslint-disable-next-line no-undef
            $('#modal').modal('hide')
            router.push('/environments')
        }
        const _token = computed(() => {
            return store.state.user.token
        })
        const _envId = computed(() => {
            return route.params.id
        })

        const state = reactive({
            loading: true,
            jsonContent: false,
            sameJson: true,
            responseSuccess: false,
            responseTitle: '',
            responseMessage: '',
            environment: {
                id: '',
                name: '',
                json: '',
            },
            form: {
                name: '',
                json: '',
            },
        })

        return {
            state,
            applyChanges,
            next,
        }
    },
}
