<template>
    <div class="view">
        <div v-if="state.loading" class="presentation w-100">
            <div class="spinner-border mt-5" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <div v-else class="presentation w-100">
            <svg
                class="mb-4"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="64"
                height="64"
                viewBox="0 0 172 172"
                style="fill: #000000"
            >
                <g
                    fill="none"
                    fill-rule="nonzero"
                    stroke="none"
                    stroke-width="1"
                    stroke-linecap="butt"
                    stroke-linejoin="miter"
                    stroke-miterlimit="10"
                    stroke-dasharray=""
                    stroke-dashoffset="0"
                    font-family="none"
                    font-weight="none"
                    font-size="none"
                    text-anchor="none"
                    style="mix-blend-mode: normal"
                >
                    <path d="M0,172v-172h172v172z" fill="none"></path>
                    <g fill="#eb611c">
                        <path
                            d="M17.2,13.76c-5.66077,0 -10.32,4.66306 -10.32,10.32v30.96v34.4v58.48c0,5.65694 4.65923,10.32 10.32,10.32h137.6c5.65902,0 10.32,-4.66098 10.32,-10.32v-58.48v-34.4v-17.2c0,-5.65902 -4.66098,-10.32 -10.32,-10.32h-92.88c0.2978,0 -0.10667,0.00125 -0.94735,-0.9675c-0.84067,-0.96875 -1.87545,-2.5759 -2.95625,-4.3c-1.08079,-1.7241 -2.21349,-3.5637 -3.64156,-5.18016c-1.42807,-1.61645 -3.39833,-3.31234 -6.21485,-3.31234zM17.2,20.64h30.96c-0.20898,0 0.21032,0.02411 1.06156,0.98766c0.85125,0.96355 1.88731,2.56394 2.96297,4.27984c1.07566,1.7159 2.18958,3.54875 3.58781,5.16c1.39823,1.61125 3.32316,3.3325 6.14765,3.3325h92.88c1.9365,0 3.44,1.5035 3.44,3.44v7.525c-1.08323,-0.39065 -2.22966,-0.645 -3.44,-0.645h-137.6c-1.21034,0 -2.35677,0.25435 -3.44,0.645v-21.285c0,-1.93858 1.49837,-3.44 3.44,-3.44zM17.2,51.6h137.6c1.9437,0 3.44,1.4963 3.44,3.44v34.4v58.48c0,1.9365 -1.5035,3.44 -3.44,3.44h-137.6c-1.94163,0 -3.44,-1.50142 -3.44,-3.44v-58.48v-34.4c0,-1.9437 1.4963,-3.44 3.44,-3.44z"
                        ></path>
                    </g>
                </g>
            </svg>
            <h3>{{ state.collection.name }}</h3>
            <small>{{ state.collection.id }}</small>
        </div>
        <div class="content w-100">
            <div class="card mb-5">
                <div class="card-body">
                    <form
                        class="cardContent"
                        @submit.prevent="updateCollection"
                    >
                        <div class="form-group">
                            <div class="formHeader">
                                <h4>Edit environment</h4>
                                <button type="submit" class="btn primary mb-3">
                                    <p class="m-0 p-2">Apply Changes</p>
                                    <i class="bi bi-pencil-square ml-3"></i>
                                </button>
                            </div>
                            <div class="dropdown-divider"></div>
                        </div>
                        <div class="form-group row">
                            <label
                                for="inputName"
                                class="col-sm-2 col-form-label"
                                >Name</label
                            >
                            <div class="col-sm-10">
                                <input
                                    required
                                    type="text"
                                    class="form-control"
                                    id="inputName"
                                    placeholder="Name"
                                    v-model="state.form.name"
                                />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label
                                for="inputUrl"
                                class="col-sm-2 col-form-label"
                                >URL</label
                            >
                            <div class="col-sm-10">
                                <input
                                    required
                                    type="url"
                                    class="form-control"
                                    id="inputUrl"
                                    placeholder="Url"
                                    v-model="state.form.url"
                                />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="inputSh" class="col-sm-2 col-form-label"
                                >Skip Headers</label
                            >
                            <div class="col-sm-10">
                                <input
                                    type="text"
                                    class="form-control"
                                    id="inputSh"
                                    placeholder="Example1,example2,example3"
                                    v-model="state.form.skipHeaders"
                                />
                                <div id="hintSh" class="form-text">
                                    <small>Separate strings using ","</small>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="inputHb" class="col-sm-2 col-form-label"
                                >Hide request body</label
                            >
                            <div class="col-sm-10">
                                <input
                                    type="text"
                                    class="form-control"
                                    id="inputHb"
                                    placeholder="Example1,example2,example3"
                                    v-model="state.form.hideRequestBody"
                                />
                                <div id="hintHb" class="form-text">
                                    <small>Separate strings using ","</small>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <!-- Modal -->
        <div
            class="modal fade"
            id="modal"
            tabindex="-1"
            aria-labelledby="modalLabel"
            aria-hidden="true"
            data-backdrop="static"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="modalLabel">
                            {{ state.responseTitle }}
                        </h5>
                        <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        {{ state.responseMessage }}
                    </div>
                    <div class="modal-footer" v-if="state.responseSuccess">
                        <button
                            type="button"
                            class="btn primary"
                            @click.prevent="next"
                        >
                            Next
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { computed, onBeforeMount, reactive } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { AXIOS_UTILS, redirectToLogin } from '@/utils/AxiosUtils'

export default {
    name: 'EditCollection',
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    setup() {
        const store = useStore()
        const route = useRoute()
        const router = useRouter()

        onBeforeMount(() => {
            AXIOS_UTILS.COLLECTIONS.GET.BY_ID(
                _token.value,
                _colId.value.toString()
            )
                .then((response) => {
                    state.collection = response.data.data
                    _setFormLayoutData()
                    state.loading = false
                })
                .catch((error) => {
                    state.loading = false
                    console.error(error)
                })
        })

        function _setFormLayoutData() {
            state.form.url = state.collection.url
            state.form.name = state.collection.name
            state.form.skipHeaders = state.collection.skipHeaders.replace(
                /[[\]"]/g,
                ''
            )
            state.form.hideRequestBody = state.collection.hideRequestBody.replace(
                /[[\]"]/g,
                ''
            )
        }

        function updateCollection() {
            AXIOS_UTILS.COLLECTIONS.PUT.UPDATE_COLLECTION(
                _token.value,
                _colId.value.toString(),
                {
                    name: state.form.name,
                    url: state.form.url,
                    skipHeaders: _reqSkipHeaders.value,
                    hideRequestBody: _reqHideReqBody.value,
                }
            )
                .then((response) => {
                    if (response.data.status.code == 0) {
                        state.responseSuccess = true
                        state.responseTitle = 'Collection updated succesfully'
                        state.responseMessage = 'Click next to continue'
                        // eslint-disable-next-line no-undef
                        $('#modal').modal('show')
                    } else if (response.data.status.code == 55)
                        redirectToLogin()
                })
                .catch((error) => {
                    console.error(error)
                })
        }

        function next() {
            // eslint-disable-next-line no-undef
            $('#modal').modal('hide')
            router.push('/collections')
        }

        const _token = computed(() => {
            return store.state.user.token
        })

        const _colId = computed(() => {
            return route.params.id
        })

        const _reqSkipHeaders = computed(() => {
            let _array = state.form.skipHeaders.split(',')
            return _array
        })

        const _reqHideReqBody = computed(() => {
            let _array = state.form.hideRequestBody.split(',')
            return _array
        })

        const state = reactive({
            name: 'Edit collections',
            loading: true,
            responseMessage: '',
            responseTitle: '',
            responseSuccess: false,
            collection: {
                id: 0,
                name: 'name',
                url: '',
                skipHeaders: '',
                hideRequestBody: '',
            },
            form: {
                url: '',
                name: '',
                skipHeaders: '',
                hideRequestBody: '',
            },
        })

        return { state, updateCollection, next }
    },
}
</script>

<style lang="scss" scoped>
.view {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;

    .presentation {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        align-self: center;
    }

    .content {
        .formHeader {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .primary {
                display: flex;
                flex-direction: row;
                align-items: baseline;
            }
        }
        .cardContent {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 40px;
        }
    }
}
</style>
