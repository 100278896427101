
import { computed, onBeforeMount, reactive } from 'vue'
import { useStore } from 'vuex'

import { AXIOS_UTILS } from '@/utils/AxiosUtils'
import { ACTIONS } from '@/store/StoreActions'
import HistoryCardList from '@/components/HistoryCardList.vue'

export default {
    name: 'Historical',
    components: {
        HistoryCardList,
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    setup() {
        const store = useStore()
        onBeforeMount(() => {
            fetchHistorical()
        })
        function fetchHistorical() {
            AXIOS_UTILS.TESTING.GET.HISTORY_LIST()
                .then((response) => {
                    store.dispatch(ACTIONS.FILL_HISTORY, response.data)
                    state.loading = false
                })
                .catch((error) => {
                    console.error(error)
                    state.loading = false
                })
        }
        const totalRuns = computed(() => {
            return store.state.test.history.total
        })

        const historical = computed(() => {
            return store.state.test.history.list
        })

        const state = reactive({
            loading: true,
            actionLabel: 'View Test Run',
        })

        function reload() {
            state.loading = true
            fetchHistorical()
        }

        return {
            state,
            totalRuns,
            historical,
            reload,
        }
    },
}
