<template>
    <div class="view">
        <div
            class="row justify-content-center align-items-center align-self-start"
        >
            <h3>Historical</h3>
            <button
                type="button"
                class="btn light ml-3"
                @click.prevent="reload"
            >
                <i class="bi bi-arrow-repeat"></i>
            </button>
        </div>
        <div class="content">
            <div v-if="state.loading" class="spinner-border mt-5" role="status">
                <span class="sr-only">Loading...</span>
            </div>
            <h5 v-else-if="totalRuns.value == 0">
                There are no test runs registered
            </h5>
            <div v-else>
                <HistoryCardList
                    :list="historical"
                    :actionLabel="state.actionLabel"
                />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { computed, onBeforeMount, reactive } from 'vue'
import { useStore } from 'vuex'

import { AXIOS_UTILS } from '@/utils/AxiosUtils'
import { ACTIONS } from '@/store/StoreActions'
import HistoryCardList from '@/components/HistoryCardList.vue'

export default {
    name: 'Historical',
    components: {
        HistoryCardList,
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    setup() {
        const store = useStore()
        onBeforeMount(() => {
            fetchHistorical()
        })
        function fetchHistorical() {
            AXIOS_UTILS.TESTING.GET.HISTORY_LIST()
                .then((response) => {
                    store.dispatch(ACTIONS.FILL_HISTORY, response.data)
                    state.loading = false
                })
                .catch((error) => {
                    console.error(error)
                    state.loading = false
                })
        }
        const totalRuns = computed(() => {
            return store.state.test.history.total
        })

        const historical = computed(() => {
            return store.state.test.history.list
        })

        const state = reactive({
            loading: true,
            actionLabel: 'View Test Run',
        })

        function reload() {
            state.loading = true
            fetchHistorical()
        }

        return {
            state,
            totalRuns,
            historical,
            reload,
        }
    },
}
</script>
<style lang="scss" scoped>
.view {
    display: flex;
    flex-direction: column;
    align-items: center;
}
</style>
