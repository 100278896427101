
import { computed, onBeforeMount, reactive } from 'vue'
import { useStore } from 'vuex'
import { AXIOS_UTILS, redirectToLogin } from '@/utils/AxiosUtils'
import { ACTIONS } from '@/store/StoreActions'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as bootstrap from 'bootstrap'

export default {
    name: 'Tests',
    components: {},
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    setup() {
        const store = useStore()

        onBeforeMount(() => {
            state.loading = true
            fetchData()
        })

        function fetchData() {
            store
                .dispatch(ACTIONS.ENVIRONMENT_CLEAR_STATE, '')
                .then(() => {
                    store
                        .dispatch(ACTIONS.COLLECTION_CLEAR_STATE, '')
                        .then(() => {
                            AXIOS_UTILS.ENVIRONMENTS.GET.LIST(_token.value)
                                .then(function (response) {
                                    if (response.data.status.code == 55)
                                        redirectToLogin()
                                    else {
                                        store
                                            .dispatch(
                                                ACTIONS.FILL_ENVIRONMENT_LIST,
                                                response.data
                                            )
                                            .then(() => {
                                                AXIOS_UTILS.COLLECTIONS.GET.LIST(
                                                    _token.value
                                                )
                                                    .then((response) => {
                                                        state.loading = false

                                                        store.dispatch(
                                                            ACTIONS.FILL_COLLECTION_LIST,
                                                            response.data
                                                        )
                                                    })
                                                    .catch((error) => {
                                                        console.error(error)
                                                        state.loading = false
                                                    })
                                            })
                                    }
                                })
                                .catch((error) => {
                                    console.error(error)
                                    state.loading = false
                                })
                        })
                })
                .catch((error) => {
                    console.error(error)
                    state.loading = false
                })
        }

        function runTest() {
            store.dispatch(ACTIONS.TOGGLE_RUN, true).then(() => {
                AXIOS_UTILS.TESTING.POST.RUN_TEST(
                    _token.value,
                    state.SelectedCollection,
                    state.SelectedEnvironment
                )
                    .then((response) => {
                        store.dispatch(ACTIONS.TOGGLE_RUN, false)
                        if (response.data.status.code == 0) {
                            state.responseMessage =
                                'Test ran successfully! Code: ' +
                                response.data.data.shareId
                        } else if (response.data.status.code == 55)
                            redirectToLogin()
                        else {
                            state.responseMessage =
                                'There has been an error running your test, please try again later'
                        }

                        // eslint-disable-next-line no-undef
                        $('#modal').modal('show')
                    })
                    .catch((error) => {
                        store.dispatch(ACTIONS.TOGGLE_RUN, false)
                        console.error(error)
                    })
            })
        }
        const _token = computed(() => {
            return store.state.user.token
        })

        const collections = computed(() => {
            return store.state.collection.list
        })

        const environments = computed(() => {
            return store.state.environment.list
        })
        const testRunning = computed(() => {
            return store.state.test.running
        })
        const state = reactive({
            loading: false,
            SelectedCollection: 0,
            SelectedEnvironment: 0,
            responseMessage: '',
        })

        return {
            runTest,
            state,
            collections,
            environments,
            testRunning,
        }
    },
}
