
import { useRouter } from 'vue-router'
import { computed, defineComponent, PropType, reactive } from 'vue'
import { AXIOS_UTILS } from '@/utils/AxiosUtils'
import { useStore } from 'vuex'

export default defineComponent({
    name: 'CardList',
    props: {
        list: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            type: Array as PropType<Array<any>>,
            required: true,
        },
        isCollectionList: {
            type: Boolean,
            required: true,
        },
    },
    setup(props) {
        const router = useRouter()
        const store = useStore()

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        function event(item: any) {
            window.open(item.url, '_blank')
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        function deleteEnv(item: any) {
            AXIOS_UTILS.ENVIRONMENTS.DELETE.DELETE_ENVIRONMENT(
                _token.value,
                item.id
            ).then(() => {
                router.push('/')
            })
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        function edit(item: any): void {
            if (props.isCollectionList) {
                router.push('/collections/edit/' + item.id)
            } else {
                router.push('/environments/edit/' + item.id)
            }
        }

        const _token = computed(() => {
            return store.state.user.token
        })

        const state = reactive({})

        return {
            state,
            event,
            edit,
            deleteEnv,
        }
    },
})
