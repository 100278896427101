/* eslint-disable */

import { Commit } from 'vuex'

export const CollectionStore = {
    namespace: true,
    state: {
        storeName: 'Collection',
        total: null,
        list: [],
    },
    mutations: {
        ADD_COLLECTION(
            state: {
                list: Record<string, unknown>[]
            },
            payload: Record<string, unknown>
        ): void {
            state.list.push(payload)
        },
        SET_TOTAL(
            state: {
                total: number
            },
            payload: number
        ): void {
            state.total = payload
        },
        CLEAR_COL(state: any, payload: any) {
            state.list = payload.list
            state.total = payload.total
        },
    },
    actions: {
        fillCollectionList(
            { commit }: { commit: Commit },
            // eslint-disable-next-line
            collectionJson: any
        ): void {
            const _collections = collectionJson.data.collections
            const totalEnvs: number = _collections.total

            if (totalEnvs > 0) {
                for (let i = 0; i < _collections.list.length; i++) {
                    const env = _collections.list[i]
                    commit('ADD_COLLECTION', env)
                }
            } else return
        },
        addCollection(
            { commit }: { commit: Commit },
            // eslint-disable-next-line
            collectionJson: any
        ): void {
            const env = collectionJson.data
            commit('ADD_COLLECTION', env)
        },
        collectionClearState({ commit }: { commit: Commit }): void {
            commit('CLEAR_COL', {
                total: null,
                list: [],
            })
        },
    },
    getters: {},
}
