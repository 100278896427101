
import { useStore } from 'vuex'
import { computed, defineComponent, reactive } from 'vue'
import { AXIOS_UTILS } from '@/utils/AxiosUtils'
import { ACTIONS } from '@/store/StoreActions'

export default defineComponent({
    name: 'NavBar',
    setup() {
        const store = useStore()
        function logOut() {
            AXIOS_UTILS.ACCOUNT.LOGOUT(_token.value)
                .then(() => {
                    store
                        .dispatch(ACTIONS.USER_CLEAR_STATE, '')
                        .then(() => window.open('/login', '_self'))
                })
                .catch((error) => {
                    console.error(error)
                })
        }

        const testRunning = computed(() => {
            return store.state.test.running
        })
        const user = computed(() => {
            return store.state.user
        })
        const _token = computed(() => {
            return store.state.user.token
        })
        const state = reactive({})
        return {
            state,
            user,
            logOut,
            testRunning,
        }
    },
})
