<template>
    <div class="root">
        <NavBar />
        <router-view class="pageView" />
    </div>
</template>

<script lang="ts">
import { defineComponent, reactive } from 'vue'
import NavBar from './components/NavBar.vue'

export default defineComponent({
    name: 'App',
    components: {
        NavBar,
    },
    setup() {
        const state = reactive({})
        return {
            state,
        }
    },
})
</script>

<style lang="scss">
#app {
    font-family: 'Poppins', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    height: 100vh;
    width: 100%;
    text-align: start;

    .root {
        width: inherit;
        height: inherit;
    }

    .pageView {
        margin: 50px 5%;
    }
}
</style>
