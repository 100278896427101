<template>
    <div class="header primary sticky-top shadow" id="nav">
        <router-link to="/" :class="{ runningTest: testRunning }">
            <img src="@/assets/svg/op_circle_logo.svg" class="opLogo"
        /></router-link>
        <div class="privateRoutes" v-if="user.online">
            <router-link to="/" :class="{ runningTest: testRunning }"
                >Run tests</router-link
            >
            <router-link
                to="/environments"
                :class="{ runningTest: testRunning }"
                >Environments</router-link
            >
            <router-link to="/collections" :class="{ runningTest: testRunning }"
                >Collections</router-link
            >
            <router-link to="/historical" :class="{ runningTest: testRunning }"
                >Historical</router-link
            >
            <div class="btn-group">
                <button
                    type="button"
                    class="btn primary dropdown-toggle ml-1 mr-3"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    :class="{ runningTest: testRunning }"
                >
                    <i class="bi bi-person-circle mr-2"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-right">
                    <div class="profile mt-5 mx-5 mb-4">
                        <i class="bi bi-person-fill mb-3"></i>
                        <strong>{{ user.account.username }}</strong>
                        <small>{{ user.account.email }}</small>
                    </div>
                    <div class="dropdown-divider"></div>
                    <button
                        class="dropdown-item align-self-center btn-light"
                        type="button"
                        @click.prevent="logOut"
                    >
                        Log out
                    </button>
                </div>
            </div>
        </div>
        <div class="publicRoutes" v-else>
            <router-link to="/historical">Historical</router-link>
            <router-link to="/login">Login</router-link>
        </div>
    </div>
</template>

<script lang="ts">
import { useStore } from 'vuex'
import { computed, defineComponent, reactive } from 'vue'
import { AXIOS_UTILS } from '@/utils/AxiosUtils'
import { ACTIONS } from '@/store/StoreActions'

export default defineComponent({
    name: 'NavBar',
    setup() {
        const store = useStore()
        function logOut() {
            AXIOS_UTILS.ACCOUNT.LOGOUT(_token.value)
                .then(() => {
                    store
                        .dispatch(ACTIONS.USER_CLEAR_STATE, '')
                        .then(() => window.open('/login', '_self'))
                })
                .catch((error) => {
                    console.error(error)
                })
        }

        const testRunning = computed(() => {
            return store.state.test.running
        })
        const user = computed(() => {
            return store.state.user
        })
        const _token = computed(() => {
            return store.state.user.token
        })
        const state = reactive({})
        return {
            state,
            user,
            logOut,
            testRunning,
        }
    },
})
</script>

<style lang="scss" scoped>
.header {
    height: 3.5rem;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .runningTest {
        pointer-events: none;
    }

    .profile {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        i {
            color: hsl(20, 84%, 52%);
            font-size: 1.875rem;
        }
    }
    a {
        margin: 0.75rem;
        font-weight: bold;
        color: #fff;

        .opLogo {
            height: 3rem;
        }
    }
}
</style>
