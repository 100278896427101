<template>
    <div class="view">
        <div class="presentation w-100">
            <div class="holder">
                <h3 class="m-0 mr-4">Collections</h3>
                <button
                    type="button"
                    class="btn primary"
                    data-toggle="modal"
                    data-target="#Modal"
                >
                    Add collection
                </button>
                <button
                    type="button"
                    class="btn light ml-3"
                    @click.prevent="reload"
                >
                    <i class="bi bi-arrow-repeat"></i>
                </button>
            </div>
        </div>
        <div class="content w-100">
            <div v-if="state.loading" class="spinner-border mt-5" role="status">
                <span class="sr-only">Loading...</span>
            </div>
            <h5 v-else-if="collection.length == 0">There are no collections</h5>
            <CardList
                v-else
                :isCollectionList="state.isCollection"
                :list="collection"
            />
        </div>
        <!-- Modal -->
        <div
            class="modal fade"
            id="Modal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="ModalLabel"
            aria-hidden="true"
        >
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="ModalLabel">
                            Add new collection
                        </h5>
                        <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <form>
                            <div class="form-group row align-items-center">
                                <label
                                    for="colName"
                                    class="col-sm-2 col-form-label"
                                    >Name</label
                                >
                                <div class="col-sm-10">
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="colName"
                                        placeholder="Name"
                                        autocomplete="nickname"
                                        v-model="state.form.name"
                                    />
                                </div>
                            </div>
                            <div class="form-group row align-items-center">
                                <label for="url" class="col-sm-2 col-form-label"
                                    >url</label
                                >
                                <div class="col-sm-10">
                                    <input
                                        type="url"
                                        class="form-control"
                                        id="url"
                                        placeholder="https://www.getpostman.com/collections/..."
                                        autocomplete="url"
                                        v-model="state.form.url"
                                    />
                                </div>
                            </div>
                            <div class="form-group row align-items-center">
                                <label
                                    for="sHeaders"
                                    class="col-sm-2 col-form-label"
                                    >Skip headers</label
                                >
                                <div class="col-sm-10">
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="sHeaders"
                                        placeholder="Example 1, example 2, example3"
                                        autocomplete="text"
                                        aria-describedby="reqHeaders"
                                        v-model="state.form.skipHeaders"
                                    />
                                    <div id="reqHeaders" class="form-text">
                                        <small
                                            >Separate strings using ","</small
                                        >
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row align-items-center">
                                <label
                                    for="hBody"
                                    class="col-sm-2 col-form-label"
                                    >Hide request body</label
                                >
                                <div class="col-sm-10">
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="hBody"
                                        placeholder="Example 1, example 2, example3"
                                        autocomplete="text"
                                        aria-describedby="reqBodyHint"
                                        v-model="state.form.hideRequestBody"
                                    />
                                    <div id="reqBodyHint" class="form-text">
                                        <small
                                            >Separate strings using ","</small
                                        >
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button
                            type="button"
                            class="btn btn-secondary"
                            data-dismiss="modal"
                        >
                            Cancel
                        </button>
                        <div
                            v-if="state.form.formLoading"
                            class="spinner-border mx-5"
                            role="status"
                        >
                            <span class="sr-only">Loading...</span>
                        </div>
                        <button
                            v-else
                            type="button"
                            class="btn primary"
                            @click.prevent="addColection"
                        >
                            Add collection
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { computed, onBeforeMount, reactive } from 'vue'
import { useStore } from 'vuex'

import CardList from '@/components/CardList.vue'
import { ACTIONS } from '@/store/StoreActions'
import { AXIOS_UTILS, redirectToLogin } from '@/utils/AxiosUtils'

export default {
    name: 'Collections',
    components: { CardList },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    setup() {
        const store = useStore()

        onBeforeMount(() => {
            fetchCollections()
        })

        function fetchCollections() {
            state.loading = true
            store.dispatch(ACTIONS.COLLECTION_CLEAR_STATE).then(() => {
                AXIOS_UTILS.COLLECTIONS.GET.LIST(_token.value)
                    .then((response) => {
                        if (response.data.status.code == 0) {
                            store.dispatch(
                                ACTIONS.FILL_COLLECTION_LIST,
                                response.data
                            )
                            state.loading = false
                        } else if (response.data.status.code == 55)
                            redirectToLogin()
                    })
                    .catch((error) => {
                        console.error(error)
                        state.loading = false
                    })
            })
        }

        function reload() {
            state.loading = true
            fetchCollections()
        }

        function addColection(): void {
            state.form.formLoading = true
            AXIOS_UTILS.COLLECTIONS.POST.NEW_COLLECTION(
                _token.value,
                state.form.name,
                state.form.url,
                _reqSkipHeaders.value ?? [''],
                _reqHideReqBody.value ?? ['']
            )
                .then((response) => {
                    state.form.formLoading = false
                    if (response.data.status.code != 55) {
                        // eslint-disable-next-line no-undef
                        $('#Modal .close').trigger('click')
                        state.form = {
                            formLoading: false,
                            name: '',
                            url: '',
                            skipHeaders: '',
                            hideRequestBody: '',
                        }
                    } else redirectToLogin()
                })
                .catch((error) => {
                    console.error(error)
                    state.form.formLoading = false
                })
        }

        const _token = computed(() => {
            return store.state.user.token
        })

        const collection = computed(() => {
            return store.state.collection.list
        })

        const _reqSkipHeaders = computed(() => {
            let _array = state.form.skipHeaders.split(',')
            return _array
        })

        const _reqHideReqBody = computed(() => {
            let _array = state.form.hideRequestBody.split(',')
            return _array
        })

        const state = reactive({
            actionLabel: 'Open collection',
            loading: true,
            isCollection: true,
            form: {
                formLoading: false,
                name: '',
                url: '',
                skipHeaders: '',
                hideRequestBody: '',
            },
        })

        return { state, addColection, collection, reload }
    },
}
</script>

<style lang="scss" scoped>
.view {
    display: flex;
    flex-direction: column;
    align-items: center;

    .presentation {
        .holder {
            display: flex;
            flex-direction: row;
        }
    }
}
</style>
