/* eslint-disable */
import { Commit } from 'vuex'

export const UserStore = {
    namespace: true,
    state: {
        storeName: 'User',
        online: null,
        token: null,
        account: {
            username: null,
            email: null,
            disabled: null,
        },
    },
    mutations: {
        LOGIN(
            state: { online: boolean; token: string },
            payload: string
        ): void {
            state.token = payload
            state.online = true
        },
        SET_DATA(
            state: {
                account: { username: string; email: string; disabled: boolean }
            },
            payload: any
        ): void {
            state.account.username = payload.account.username
            state.account.email = payload.account.email
            state.account.disabled = payload.account.disabled
        },
        CLEAR_USER(state: any, payload: any) {
            state.account.username = payload.account.username
            state.account.email = payload.account.email
            state.account.disabled = payload.account.disabled
            state.account.token = payload.account.token
            state.account.online = payload.account.online
        },
    },
    actions: {
        login({ commit }: { commit: Commit }, authJson: any): void {
            const _token = authJson.data.token
            commit('LOGIN', _token)
        },
        setData({ commit }: { commit: Commit }, userJson: any): void {
            commit('SET_DATA', userJson.data)
        },
        userClearState({ commit }: { commit: Commit }): void {
            commit('CLEAR_USER', {
                online: null,
                token: null,
                account: {
                    username: null,
                    email: null,
                    disabled: null,
                },
            })
        },
    },
    getters: {},
}
