/* eslint-disable */
import { Commit } from 'vuex'

export const TestStore = {
    namespace: true,
    state: {
        storeName: 'Test',
        running: false,
        history: {
            total: 0,
            list: [],
        },
    },
    mutations: {
        FILL_HISTORY(state: { history: { list: any[] } }, payload: any): void {
            state.history.list.push(payload)
        },
        TOGGLE_RUN(state: { running: boolean }, payload: boolean) {
            state.running = payload
        },
    },
    actions: {
        fillHistory({ commit }: { commit: Commit }, historyJson: any) {
            const _historical = historyJson.data
            const totalRuns: number = _historical.total

            if (totalRuns > 0) {
                for (let i = 0; i < _historical.list.length; i++) {
                    const run = _historical.list[i]
                    commit('FILL_HISTORY', run)
                }
            } else return
        },
        toggleRun({ commit }: { commit: Commit }, payload: boolean) {
            commit('TOGGLE_RUN', payload)
        },
    },
    getters: {},
}
