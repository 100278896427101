<template>
    <div class="view">
        <div class="presentation w-50">
            <img
                class="mt-4"
                src="@/assets/images/op_full_logo.png"
                alt="Op Logo"
            />
        </div>
        <div class="content w-50">
            <form class="card p-4 m-4" @submit.prevent="login">
                <div class="formLayout">
                    <div class="head my-5 w-75">
                        <h3 class="m-0"><strong>Login</strong></h3>
                        <div class="dropdown-divider"></div>
                    </div>
                    <div class="form-group mb-5 w-75">
                        <label for="username">Username</label>
                        <input
                            required
                            autocomplete="current-username"
                            type="text"
                            class="form-control"
                            id="username"
                            placeholder="Enter username"
                            v-model="state.username"
                        />
                    </div>
                    <div class="form-group w-75">
                        <label for="password">Password</label>
                        <input
                            required
                            autocomplete="current-password"
                            type="password"
                            class="form-control"
                            id="password"
                            placeholder="Password"
                            v-model="state.password"
                        />
                    </div>
                    <button
                        type="submit"
                        class="btn primary py-3 w-50 align-self-center my-5"
                    >
                        Login
                    </button>
                </div>
            </form>
        </div>
        <!-- Modal -->
        <div
            class="modal fade"
            id="modal"
            tabindex="-1"
            aria-labelledby="modalLabel"
            aria-hidden="true"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="modalLabel">
                            There has been an error!
                        </h5>
                        <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        {{ state.errorMessage }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { computed, reactive } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { AXIOS_UTILS } from '@/utils/AxiosUtils'
import { ACTIONS } from '@/store/StoreActions'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as bootstrap from 'bootstrap'

export default {
    name: 'Login',
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    setup() {
        const store = useStore()
        const router = useRouter()

        function validate(): boolean {
            return true
        }
        function login() {
            if (validate()) {
                AXIOS_UTILS.ACCOUNT.LOGIN(state.username, state.password)
                    .then((response) => {
                        if (
                            response.data.status.code === 0 ||
                            response.data.status.code === 53
                        ) {
                            store
                                .dispatch(ACTIONS.LOGIN, response.data)
                                .then(() => {
                                    AXIOS_UTILS.ACCOUNT.GET_DATA(_token.value)
                                        .then((response) => {
                                            store.dispatch(
                                                ACTIONS.SET_DATA,
                                                response.data
                                            )
                                            router.push('/')
                                        })
                                        .catch((error) => {
                                            console.error(error)
                                        })
                                })
                        } else {
                            state.errorMessage = response.data.status.desc

                            // eslint-disable-next-line no-undef
                            $('#modal').modal('show')
                        }
                    })
                    .catch((error) => {
                        console.error(error)
                    })
            }
        }
        const _token = computed(() => {
            return store.state.user.token
        })
        const state = reactive({
            loading: false,
            username: '',
            password: '',
            errorMessage: '',
        })

        return {
            state,
            login,
        }
    },
}
</script>

<style lang="scss" scoped>
.view {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;

    .presentation {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
    }

    .formLayout {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}
</style>
