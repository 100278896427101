/* eslint-disable */
import axios, { AxiosPromise, AxiosRequestConfig } from 'axios'

export const URL = 'https://api-testing-backend.openpass.com.ar'
export function redirectToLogin() {
    window.open('/login', '_self')
}

const _axiosConfigs = {
    PUBLIC: {
        _buildGetAxiosConfig: function (
            endpoint: string,
            data: any
        ): AxiosRequestConfig {
            const config: AxiosRequestConfig = {
                method: 'get',
                url: URL + endpoint,
                data: data,
            }
            return config
        },
        _buildPostAxiosConfig: function (
            endpoint: string,
            data: any
        ): AxiosRequestConfig {
            const config: AxiosRequestConfig = {
                method: 'post',
                url: URL + endpoint,
                data: data,
            }
            return config
        },
    },

    PRIVATE: {
        _buildGetAxiosConfig: function (
            endpoint: string,
            token: string,
            data: any
        ): AxiosRequestConfig {
            const config: AxiosRequestConfig = {
                method: 'get',
                url: URL + endpoint,
                headers: {
                    Authorization: token,
                },
                data: data,
            }
            return config
        },
        _buildPostAxiosConfig: function (
            endpoint: string,
            token: string,
            data: any
        ): AxiosRequestConfig {
            const config: AxiosRequestConfig = {
                method: 'post',
                url: URL + endpoint,
                headers: {
                    Authorization: token,
                },
                data: data,
            }
            return config
        },
        _buildPutAxiosConfig: function (
            endpoint: string,
            token: string,
            data: any
        ): AxiosRequestConfig {
            const config: AxiosRequestConfig = {
                method: 'put',
                url: URL + endpoint,
                headers: {
                    Authorization: token,
                },
                data: data,
            }
            return config
        },
        _buildDeleteAxiosConfig: function (
            endpoint: string,
            token: string,
            data: any
        ): AxiosRequestConfig {
            const config: AxiosRequestConfig = {
                method: 'delete',
                url: URL + endpoint,
                headers: {
                    Authorization: token,
                },
                data: data,
            }
            return config
        },
    },
}

export const AXIOS_UTILS = {
    ACCOUNT: {
        LOGIN: (username: string, password: string): AxiosPromise<any> =>
            axios(
                _axiosConfigs.PUBLIC._buildPostAxiosConfig('/auth/user/login', {
                    username: username,
                    password: password,
                })
            ),
        GET_DATA: (token: string): AxiosPromise<any> =>
            axios(
                _axiosConfigs.PRIVATE._buildGetAxiosConfig(
                    '/auth/user/account',
                    token,
                    {}
                )
            ),
        LOGOUT: (token: string): AxiosPromise<any> =>
            axios(
                _axiosConfigs.PRIVATE._buildPostAxiosConfig(
                    '/auth/user/logout',
                    token,
                    {}
                )
            ),
    },
    ENVIRONMENTS: {
        GET: {
            LIST: (token: string): AxiosPromise<any> =>
                axios(
                    _axiosConfigs.PRIVATE._buildGetAxiosConfig(
                        '/testing/environment/list',
                        token,
                        {}
                    )
                ),
            BY_ID: (token: string, id: string): AxiosPromise<any> =>
                axios(
                    _axiosConfigs.PRIVATE._buildGetAxiosConfig(
                        '/testing/environment/get?id=' + id,
                        token,
                        {}
                    )
                ),
        },
        POST: {
            NEW_ENVIRONMENT: (token: string, body: any): AxiosPromise<any> =>
                axios(
                    _axiosConfigs.PRIVATE._buildPostAxiosConfig(
                        '/testing/environment/insert',
                        token,
                        body
                    )
                ),
        },
        PUT: {
            UPDATE_ENVIRONMENT: (
                token: string,
                id: string,
                body: any
            ): AxiosPromise<any> =>
                axios(
                    _axiosConfigs.PRIVATE._buildPutAxiosConfig(
                        '/testing/environment/update?id=' + id,
                        token,
                        body
                    )
                ),
        },
        DELETE: {
            DELETE_ENVIRONMENT: (
                token: string,
                id: string
            ): AxiosPromise<any> =>
                axios(
                    _axiosConfigs.PRIVATE._buildDeleteAxiosConfig(
                        '/testing/environment/delete?id=' + id,
                        token,
                        {}
                    )
                ),
        },
    },
    COLLECTIONS: {
        GET: {
            LIST: (token: string): AxiosPromise<any> =>
                axios(
                    _axiosConfigs.PRIVATE._buildGetAxiosConfig(
                        '/testing/collection/list',
                        token,
                        {}
                    )
                ),
            BY_ID: (token: string, id: string): AxiosPromise<any> =>
                axios(
                    _axiosConfigs.PRIVATE._buildGetAxiosConfig(
                        '/testing/collection/get?id=' + id,
                        token,
                        {}
                    )
                ),
        },
        POST: {
            NEW_COLLECTION: (
                token: string,
                name: string,
                url: string,
                skipHeaders: Array<string>,
                hideRequestBody: Array<string>
            ): AxiosPromise<any> =>
                axios(
                    _axiosConfigs.PRIVATE._buildPostAxiosConfig(
                        '/testing/collection/insert',
                        token,
                        {
                            name: name,
                            url: url,
                            skipHeaders: skipHeaders,
                            hideRequestBody: hideRequestBody,
                        }
                    )
                ),
        },
        PUT: {
            UPDATE_COLLECTION: (
                token: string,
                id: string,
                body: any
            ): AxiosPromise<any> =>
                axios(
                    _axiosConfigs.PRIVATE._buildPutAxiosConfig(
                        '/testing/collection/update?id=' + id,
                        token,
                        body
                    )
                ),
        },
    },
    TESTING: {
        GET: {
            HISTORY_LIST: (): AxiosPromise<any> =>
                axios(
                    _axiosConfigs.PUBLIC._buildGetAxiosConfig(
                        '/testing/history/list',
                        {}
                    )
                ),
        },
        POST: {
            RUN_TEST: (
                token: string,
                colId: number,
                envId: number
            ): AxiosPromise<any> =>
                axios(
                    _axiosConfigs.PRIVATE._buildPostAxiosConfig(
                        '/testing/test/run',
                        token,
                        {
                            collectionId: colId,
                            environmentId: envId,
                        }
                    )
                ),
        },
    },
}
