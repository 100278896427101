import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Login from '../views/Login.vue'
import Tests from '../views/Tests.vue'
import Environments from '../views/Environments.vue'
import EditEnvironment from '../views/EditEnvironment.vue'
import Collections from '../views/Collections.vue'
import EditCollection from '../views/EditCollection.vue'
import Historical from '../views/Historical.vue'
import store from '@/store'
import { Store } from 'vuex'

// eslint-disable-next-line
const _store: Store<any> = store
const routes: Array<RouteRecordRaw> = [
    {
        path: '/login',
        name: 'Login',
        component: Login,
    },
    {
        path: '/historical',
        name: 'Historical',
        component: Historical,
    },
    {
        path: '/',
        name: 'Tests',
        component: Tests,
        meta: {
            reqOnline: true,
        },
    },
    {
        path: '/environments',
        name: 'Environments',
        component: Environments,
        meta: {
            reqOnline: true,
        },
    },
    {
        path: '/environments/edit/:id',
        name: 'EditEnvironment',
        component: EditEnvironment,
        meta: {
            reqOnline: true,
        },
    },
    {
        path: '/collections',
        name: 'Collections',
        component: Collections,
        meta: {
            reqOnline: true,
        },
    },
    {
        path: '/collections/edit/:id',
        name: 'EditCollection',
        component: EditCollection,
        meta: {
            reqOnline: true,
        },
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
})

router.beforeEach(async (to, from, next) => {
    const isOnline = _store.state.user.online
    const reqOnline = to.matched.some((record) => record.meta.reqOnline)
    if (reqOnline && !isOnline) {
        next({ name: 'Login' })
    } else {
        next()
    }
})

export default router
