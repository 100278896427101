export const ACTIONS = {
    //? COLLECTION STORE
    FILL_COLLECTION_LIST: 'fillCollectionList',
    ADD_COLLECTION: 'addCollection',
    COLLECTION_CLEAR_STATE: 'collectionClearState',
    //? ENVIRONMENT STORE
    FILL_ENVIRONMENT_LIST: 'fillEnvironmentList',
    ADD_ENVIRONMENT: 'addEnvironment',
    ENVIRONMENT_CLEAR_STATE: 'environmentClearState',
    //? TEST STORE
    FILL_HISTORY: 'fillHistory',
    TOGGLE_RUN: 'toggleRun',
    //? USER STORE
    LOGIN: 'login',
    SET_DATA: 'setData',
    USER_CLEAR_STATE: 'userClearState',
}
