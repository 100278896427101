<template>
    <div class="view">
        <div class="presentation w-100">
            <div class="holder">
                <h3 class="m-0 mr-4">Environments</h3>
                <button
                    type="button"
                    class="btn primary"
                    data-toggle="modal"
                    data-target="#Modal"
                >
                    Add env
                </button>
                <button
                    type="button"
                    class="btn light ml-3"
                    @click.prevent="reload"
                >
                    <i class="bi bi-arrow-repeat"></i>
                </button>
            </div>
        </div>
        <div class="content w-100">
            <div v-if="state.loading" class="spinner-border mt-5" role="status">
                <span class="sr-only">Loading...</span>
            </div>
            <h5 v-else-if="environments.length == 0">
                There are no environments
            </h5>
            <CardList
                v-else
                :isCollectionList="state.isCollection"
                :list="environments"
            />
        </div>

        <!-- Modal -->
        <div
            class="modal fade"
            id="Modal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="ModalLabel"
            aria-hidden="true"
        >
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="ModalLabel">
                            Add new environment
                        </h5>
                        <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <form>
                            <div class="form-group">
                                <label for="FormControlFile1"
                                    >Select your
                                    <strong>.json</strong> file</label
                                >
                                <input
                                    type="file"
                                    class="form-control-file"
                                    id="FormControlFile1"
                                    accept=".json"
                                    @change="readFile"
                                />
                            </div>
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button
                            type="button"
                            class="btn btn-secondary"
                            data-dismiss="modal"
                        >
                            Cancel
                        </button>
                        <div
                            v-if="state.form.formLoading"
                            class="spinner-border mx-5"
                            role="status"
                        >
                            <span class="sr-only">Loading...</span>
                        </div>
                        <button
                            v-else
                            type="button"
                            class="btn primary"
                            @click="addEnv"
                        >
                            Add environment
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { computed, onBeforeMount, reactive } from 'vue'
import { useStore } from 'vuex'

import CardList from '@/components/CardList.vue'
import { AXIOS_UTILS, redirectToLogin } from '@/utils/AxiosUtils'
import { ACTIONS } from '@/store/StoreActions'
import { useRouter } from 'vue-router'

export default {
    name: 'Environments',
    components: { CardList },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    setup() {
        const store = useStore()
        const router = useRouter()

        onBeforeMount(() => {
            fetchEnvironments()
        })

        function fetchEnvironments() {
            state.loading = true
            store.dispatch(ACTIONS.ENVIRONMENT_CLEAR_STATE, '').then(() => {
                AXIOS_UTILS.ENVIRONMENTS.GET.LIST(_token.value)
                    .then((response) => {
                        if (response.data.status.code == 0) {
                            store.dispatch(
                                ACTIONS.FILL_ENVIRONMENT_LIST,
                                response.data
                            )
                            state.loading = false
                        } else if (response.data.status.code == 55)
                            redirectToLogin()
                    })
                    .catch((error) => {
                        console.error(error)
                        state.loading = false
                    })
            })
        }
        function reload() {
            state.loading = true
            fetchEnvironments()
        }

        function addEnv(): void {
            state.form.formLoading = true
            AXIOS_UTILS.ENVIRONMENTS.POST.NEW_ENVIRONMENT(
                _token.value,
                JSON.parse(state.form.newEnvJson)
            )
                .then((response) => {
                    state.form.formLoading = false

                    if (response.data.status.code != 55) {
                        // eslint-disable-next-line no-undef
                        $('#Modal .close').trigger('click')
                        router.push('/')
                    } else redirectToLogin()
                })
                .catch((error) => {
                    console.error(error)
                    state.form.formLoading = false
                })
        }

        const readFile = (event: { target: { files: never } }): void => {
            const _reader = new FileReader()

            _reader.onload = function (e): void {
                state.form.newEnvJson = e.target?.result?.toString() || ''
            }
            _reader.readAsText(event.target.files[0])
        }

        const _token = computed(() => {
            return store.state.user.token
        })

        const environments = computed(() => {
            return store.state.environment.list
        })

        const state = reactive({
            actionLabel: 'Edit env',
            loading: true,
            isCollection: false,
            form: {
                formLoading: false,
                newEnvJson: '',
            },
        })

        return { state, addEnv, environments, readFile, reload }
    },
}
</script>

<style lang="scss" scoped>
.view {
    display: flex;
    flex-direction: column;
    align-items: center;

    .presentation {
        .holder {
            display: flex;
            flex-direction: row;
        }
    }
}
</style>
